import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { navigate } from "gatsby";
import { theme } from "../../../utils/global-styles/theme";
import stageThreeData from "../../../hooks/payments-data-three";
import PrimaryButton from "../../shared/PrimaryButton/PrimaryButton";

const PaymentConfirmation = () => {
  const verbiage = stageThreeData(),
    Wrapper = styled.div`
      padding: 0 120px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        padding: 0 50px;
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 0 10px;
      }
    `,
    Heading = styled.h1`
      font-size: 53px;
      font-weight: bold;
      letter-spacing: -1.38px;
      margin: 50px 0 40px 0;
      line-height: 1em;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 36px;
        font-weight: bold;
        letter-spacing: -0.94px;
        margin: 30px 0 20px 0;
      }
    `,
    Subheading = styled.h5`
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -0.09px;
      margin-bottom: 50px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.06px;
        margin-bottom: 20px;
      }
    `,
    ConfirmationText = styled.p`
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.22px;
      font-family: ${theme.fontFaces.headers};
      margin-bottom: 40px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.18px;
        margin-bottom: 20px;
      }
    `;

  return (
    <Wrapper>
      <Heading>{verbiage.heading}</Heading>
      <Subheading>{verbiage.shortDescription}</Subheading>
      <ConfirmationText>{verbiage.additionalText}</ConfirmationText>
      <PrimaryButton
        text={verbiage.contentModules[0].linkText}
        handleClick={() => {
          const pendingCourseEnrollmentDetails = localStorage.getItem(
            "pendingCourseEnrollmentDetails"
          );
          if (pendingCourseEnrollmentDetails) {
            localStorage.removeItem("pendingCourseEnrollmentDetails");
          }
          navigate(`${verbiage.contentModules[0].url}/`);
        }}
        styles={css`
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.4px;
          padding: 8px 32px;
          margin-bottom: 120px;
          @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
            margin-bottom: 50px;
          }
        `}
      />
    </Wrapper>
  );
};

export default PaymentConfirmation;
