import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { theme } from "../../../utils/global-styles/theme";
import PrimaryButton from "../../../components/shared/PrimaryButton/PrimaryButton";

const PromoInput = ({
  paymentToken,
  updateTotal,
  promoVerbiage,
  buttonText,
  promo,
}) => {
  const [promoCode, setPromoCode] = useState("");

  return (
    <>
      <input
        type="text"
        value={promoCode}
        onChange={e => setPromoCode(e.target.value)}
        id="in_promo"
        placeholder={promoVerbiage}
        disabled={promo}
        className="primary-placeholder"
        css={css`
          width: 80%;
          border: none;
          caret-color: ${theme.colors.primary};
          &:focus {
            outline: none;
          }
          ::placeholder {
            font-weight: 200;
            font-size: 14px;
            font-weight: bold;
          }
          @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
            width: 70%;
          }
        `}
      />
      <PrimaryButton
        disabled={!promoCode.length || promo}
        text={buttonText}
        handleClick={e => updateTotal(e, null, null, promoCode)}
        styles={css`
          width: 20%;
          font-size: 14px;
          @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
            width: 30%;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.34px;
          }
        `}
      />
    </>
  );
};

PromoInput.propTypes = {
  paymentToken: PropTypes.string,
  updateTotal: PropTypes.func,
  promoVerbiage: PropTypes.string,
  buttonText: PropTypes.string,
  promo: PropTypes.string,
};

export default PromoInput;
